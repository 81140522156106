import * as R from 'rambdax'
import {useEffect, useRef} from 'react'
import {Falsy, logger} from 'tizra'
import {ValueOf} from 'type-fest'
import {useForceRender} from './useForceRender'

const log = logger('usePromises')

interface StateItem {
  promise?: Promise<any> | Falsy
  status: 'falsy' | 'pending' | 'resolved' | 'rejected'
  result?: unknown
  error?: unknown
}

type UsePromisesArg<K extends string> = Record<K, Promise<any> | Falsy> | Falsy

type UsePromisesReturn<K extends string> = Record<K, StateItem>

/**
 * React hook for resolving promises.
 *
 *    const promised = usePromises({
 *      one: () => Promise.resolve('yay'),
 *      two: () => Promise.resolve('hang on'),
 *      three: false && (() => Promise.resolve('later')),
 *      four: () => Promise.reject('whoops'),
 *    })
 *
 * Given an object of key-promise pairs, returns an object of those promises
 * plus their current status (all start pending). Each time a promise completes,
 * returns current statuses:
 *
 *    {
 *      one: {promise: p1, status: 'resolved', result},
 *      two: {promise: p2, status: 'pending'},
 *      three: {promise: false, status: 'falsy'},
 *      four: {promise: p4, status: 'rejected', error},
 *    }
 *
 * In the spirit of react-query, any promise can be falsy, and it's also valid
 * to pass an empty object or falsy value for the entire object.
 *
 * The object of promises is allowed to change between calls to usePromises. New
 * keys will be added to state, missing keys will be dropped. If the value
 * associated with a key changes from truthy to falsy, this will be ignored.
 * Truthy values should be identical, though, otherwise they will be replaced.
 */
export const usePromises = <K extends string>(
  _promises: UsePromisesArg<K>,
): UsePromisesReturn<K> => {
  const promises = _promises || ({} as Exclude<UsePromisesArg<K>, Falsy>)
  const self = useRef<{
    state: Record<string, StateItem>
  }>({state: {}}).current
  const force = useForceRender()

  // Prevent outstanding promises from attempting rerender when the component is
  // unmounted.
  useEffect(
    () => () => {
      self.state = {}
    },
    [], // eslint-disable-line react-hooks/exhaustive-deps
  )

  // When a promise completes, update state and force rerender.
  const put = (k: string, promise: Promise<any>, obj: StateItem) => {
    const {state} = self
    if (!state[k]) {
      log.warn('(put) ignoring dropped promise %s', k)
      return
    }
    if (state[k].promise !== promise) {
      log.warn('(put) ignoring replaced promise %s', k)
      return
    }
    if (state[k].status !== 'pending') {
      log.warn('(put) unexpected promises[%s].state = %s', k, state[k].status)
    }
    log.debug?.('(put) %s %s', obj.status, k, obj.result || obj.error)
    state[k] = {...state[k], ...obj}
    force()
  }

  // Build new state from incoming promises. Any missing keys will be
  // unceremoniously dropped. Use a flag to track updatedState since it's faster
  // than comparing afterward.
  const {state} = self
  const droppedKeys = R.difference(Object.keys(state), Object.keys(promises))
  if (droppedKeys.length) {
    log.debug?.('dropping keys', droppedKeys)
  }
  let updatedState = !!droppedKeys.length
  const newState = R.map((promise: ValueOf<typeof promises>, k: string) => {
    if (state[k]) {
      if (!promise) {
        // Never transition back to falsy
        log.debug?.('falsy promise, keeping state for %s', k)
        return state[k]
      }
      if (state[k].promise === promise) {
        // Don't re-init with same promise
        log.debug?.('same promise, keeping state for %s', k)
        return state[k]
      }
    }
    updatedState = true
    const newStateItem: StateItem = {
      promise,
      status: promise ? 'pending' : 'falsy',
    }
    log.debug?.(
      '%s state for %s',
      state[k]?.promise ? 'replacing truthy'
      : state[k] ? 'replacing falsy'
      : 'initializing',
      k,
      newStateItem,
    )
    if (promise) {
      promise.then(
        result => put(k, promise, {status: 'resolved', result}),
        error => put(k, promise, {status: 'rejected', error}),
      )
    }
    return newStateItem
  }, promises)

  if (updatedState) {
    log.debug?.('new state, how exciting!', newState)
    self.state = newState
  }

  // Cast keys from string to K
  return self.state as UsePromisesReturn<K>
}

/**
 * Singular version of usePromises for convenience.
 */
export const usePromise = (pinky: Promise<any> | Falsy) =>
  usePromises({pinky}).pinky
