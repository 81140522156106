import {useState, useEffect} from 'react'

export const useNextFrame = <T>(value: T) => {
  const [delayedValue, setDelayedValue] = useState<T>(value)

  useEffect(() => {
    const id = requestAnimationFrame(() => setDelayedValue(value))
    return () => cancelAnimationFrame(id)
  }, [value])

  return delayedValue
}
